.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 50px 10px;
}

.logo {
  height: auto;
  width: auto;
  max-height: 80px;
  max-width: 100%;
}

.logoLink {
  display: block;
  text-align: center;
}

.text:first-child {
  margin-right: 10px;
  justify-content: initial;
}

.text:last-child {
  margin-left: 10px;
  justify-content: initial;
}

.text {
  flex: initial;
  justify-content: initial;
}

.textLine {
  padding-top: 1.5em;
  display: flex;
  flex-wrap: wrap;
}

.text:first-child {
  margin-right: auto;
  justify-content: flex-start;
}

.text:last-child {
  margin-left: auto;
  justify-content: flex-end;
}

.text {
  flex: 1;
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 2em;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: 600;
  font-style: normal;
  color: rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) {
  .container {
    padding: 100px 100px 0;
  }
}

@media (max-width: 767px) {
  .textLine {
    justify-content: center;
  }

  .text:first-child {
    margin-right: 10px;
    justify-content: initial;
  }

  .text:last-child {
    margin-left: 10px;
    justify-content: initial;
  }

  .text {
    flex: initial;
    justify-content: initial;
  }
}
