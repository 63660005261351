.imageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-height: 240px;
}

.image {
  object-fit: cover;
  max-width: 98%;
  border-radius: 12px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button {
  width: 40%;
}
