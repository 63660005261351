.container {
  height: 48px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin: 12px 0 6px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Merriweather Sans', sans-serif !important;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 19;
  background: white;
}

.button {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-weight: 700;
  line-height: 24px;
  color: #2e7c87;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 0 0 6px 0;
  }

  .button {
    font-size: 14px;
  }
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
}

.selectContainer {
  display: flex;
  flex-direction: row;
}

.select {
  font-size: 16px !important;
  margin: 5px !important;
  color: rgba(0, 0, 0, 0.8);
}

.select option {
  font-weight: 300 !important;
}

.updateButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.updateButton {
  flex-direction: column !important;
}
