.container {
  font-family: 'Merriweather Sans', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.h3 {
  letter-spacing: 1.1px;
  word-spacing: 3px;
}

.hr {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: none;
}

.back {
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  text-align: center;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.input {
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  height: 24px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 300;
  justify-content: center;
}

.input::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.input:focus {
  outline: none;
}

.smallInput {
  max-width: 25%;
}

.submitButton {
  margin: 32px 0;
  height: 3.5rem !important;
  word-spacing: 3px;
}

.submitButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.contactInformation {
  display: flex;
  flex-direction: column;
}

.error {
  font-size: 18px;
  color: red;
  text-align: center;
}
