.app {
  font-size: 14px;
  line-height: 1.8em;
  text-transform: none;
  font-weight: 300;
  font-style: normal;
  text-decoration: none;
}

@media screen and (min-width: 1025px) {
  .app {
    margin: 80px;
    max-width: 1300px;
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
}

@media screen and (min-width: 1460px) {
  .app {
    margin: 80px auto;
  }
}
