.headerText {
  font-family: 'Merriweather Sans', sans-serif;
  text-align: center;
  padding: 4px 12px;
  max-width: 600px;
  margin: 0 auto;
}

.headerDescription {
  margin: 4px;
}

.bannerContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.banner {
  height: 500px;
  width: 500px;
  overflow: hidden;
  border-radius: 29px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bannerImg {
  width: 100%;
  height: auto;
}

.sectionContainer {
  margin: 0 12px;
}

@media screen and (max-width: 768px) {
  .bannerContainer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .banner {
    height: 300px;
    width: 100%;
    overflow: hidden;
    border-radius: 0;
  }

  .bannerImg {
    width: 100%;
    height: auto;
  }
}
